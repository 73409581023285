import {Router, ActivatedRoute, Params} from '@angular/router';
import { Component, OnInit, HostListener, DoCheck } from '@angular/core';
import {Http, Response, Headers, RequestOptions} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements OnInit, DoCheck {
  public headers;
  public subscribed;
  public failedSubscription;

  constructor(private http: Http, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.subscribed = false;
    this.failedSubscription = false;
    this.headers = new Headers();
    this.activatedRoute.fragment.subscribe((fragment: string) => {
      if (fragment !== undefined && fragment != null) {
        this.follow(fragment.match(/^(.*?)&/)[1].replace('access_token=', ''))
      }
    })
  }

  ngDoCheck(): void {
    setTimeout(_ => '');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.computeHeight();
  }

  computeHeight() {
    const header = document.getElementById('header');
    const footer = document.getElementById('footer');
    const followLink = document.getElementById('follow-link');
    return window.innerHeight - header.offsetHeight - footer.offsetHeight - followLink.offsetHeight + 'px';
  }

  follow(token) {
    if (!token) {
      const scopes = 'user-follow-modify';
      document.location.href = 'https://accounts.spotify.com/authorize?client_id=d2af5e68235940fcbb03a1bcfa8089a2&response_type=token&scopes=' + encodeURIComponent(scopes) + '&redirect_uri=' + encodeURIComponent('http://boombox-cartel.s3-website-us-west-2.amazonaws.com/music')
    } else {
      const headerDict = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });
        return this.http.put("https://api.spotify.com/v1/me/following?type=artist&ids=4m1yRHUMhvB8gKAJTjK4kO", {}, { headers: headerDict })
        .toPromise()
        .then((data) => {
          this.subscribed = true;
        }).catch((error) => {
          this.subscribed = false;
          this.failedSubscription = true;
        });
    }
  }
}
