import { Component, OnInit } from '@angular/core';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { Jsonp } from '@angular/http';
import { SubscribeService } from '../services/subscriber/subscribe.service';
import { TourComponent } from '../tour/tour.component';
import { FooterToggle } from '../footertoggle';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', '../app.component.scss'],
  animations: [
    trigger('toggleState', [
      state('true', style({maxHeight: '300px'})),
      state('false', style({maxHeight: '0px', display: 'none'})),
      transition('false => true', animate('800ms')),
      transition('true => false', animate('400ms'))
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-45deg)' })),
      transition('rotated => default', animate('400ms')),
      transition('default => rotated', animate('800ms'))
    ])
  ]
})

export class FooterComponent implements OnInit {
  state: string = 'default';

  result: any;
  public displaySocial;
  public emailAddr;
  public showModal;
  public hovering;
  public hideMobileMusic;

  constructor(private subscriberService: SubscribeService, private router: Router,
     public footerToggle: FooterToggle, public jsonp: Jsonp) {}

  ngOnInit() {
    this.displaySocial = this.footerToggle.displaySocial;
    this.showModal = false;
    this.hovering = false;
    this.hideMobileMusic = false;
    if (window.innerWidth > 1366) {
      this.hideMobileMusic = true;
    }
  }

  public subscribe(evnt) {
    // tslint:disable-next-line:max-line-length
    const url = '//boomboxcartel.us19.list-manage.com/subscribe/post-json?u=bf9e7e27f14f1fb62efd0cf88&amp;id=dfa6eb6cc2&subscribe=Subscribe&EMAIL=' + this.emailAddr + '&c=JSONP_CALLBACK';
    this.jsonp.request(url, { method: 'Get' })
     .subscribe((res) => {
       this.result = res.json();
       if (this.result.result === 'success') {
         (<HTMLInputElement>document.getElementById('subscribe-form')).style.display = 'none';
         document.getElementById('subscribe-success').style.display = 'initial';
       } else {
         (<HTMLInputElement>document.getElementById('email')).value = '';
         (<HTMLInputElement>document.getElementById('email')).placeholder = 'Email already exists';
       }
     });
  }

  public keyBoardOpen() {
    if (window.innerWidth < 768) {
      document.getElementById('tourContainer').style.height = '0px';
    }
  }

  public toggleFooter() {
    this.footerToggle.toggleFooter();
    this.displaySocial = this.footerToggle.displaySocial;
    this.rotate();
  }

  public checkFooter() {
    if (this.footerToggle.displaySocial === 'true') {
      this.toggleFooter();
    }
  }

  isHidden(param) {
    if (this.router.url && this.router.url.indexOf(param) > -1) {
      return true;
    } else {
      return false;
    }
  }

  rotate() {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
  }
}
