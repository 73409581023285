import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TourComponent } from './tour/tour.component';
import { IndexComponent } from './index/index.component';
import { VideoComponent } from './video/video.component';
import { MusicComponent } from './music/music.component';
import { CommonModule } from '@angular/common';

const appRoutes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'tour', component: TourComponent },
  { path: 'video', component: VideoComponent },
  { path: 'music', component: MusicComponent },
  { path: '', component: IndexComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes), CommonModule],
    exports: [RouterModule]
})
export class AppRoutingModule {

}