import {
  Component,
  OnInit,
  ViewChild,
  AfterContentInit
} from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, AfterContentInit {
  @ViewChild('video') videoplayer: any;

  constructor() { }

  ngAfterContentInit() {
    if (this.videoplayer !== undefined) {
      this.videoplayer.nativeElement.muted = true;
      this.videoplayer.nativeElement.play();
    }
  }

  ngOnInit() {
  }

}
