import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule, JsonpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { Ng2Webstorage } from 'ngx-webstorage';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TourComponent } from './tour/tour.component';
import { VideoComponent } from './video/video.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SocialDirective } from './shared/social.directive';

import { FooterToggle } from './footertoggle';
import { AppConstants } from './app.constants';
import { SubscribeService } from './services/subscriber/subscribe.service';
import { ErrorComponent } from './error/error.component';
import { IndexComponent } from './index/index.component';
import { MusicComponent } from './music/music.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TourComponent,
    VideoComponent,
    GalleryComponent,
    SocialDirective,
    ErrorComponent,
    IndexComponent,
    MusicComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    FormsModule,
    JsonpModule,
    Ng2Webstorage,
    BrowserAnimationsModule
  ],
  providers: [SubscribeService, FooterToggle, AppConstants /**, {provide: LocationStrategy, useClass: HashLocationStrategy} */],
  bootstrap: [AppComponent]
})
export class AppModule { }
