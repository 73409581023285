import {
  Component,
  HostListener,
  ViewChild,
  AfterContentInit,
  OnInit,
  ElementRef
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { FooterToggle } from './footertoggle';
import { Jsonp } from '@angular/http';
import { Router } from '@angular/router';
import { SubscribeService } from './services/subscriber/subscribe.service';
import { SessionStorageService } from 'ngx-webstorage';
import { FooterComponent } from './footer/footer.component';

@Component({
selector: 'app-root',
templateUrl: './app.component.html',
styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentInit, OnInit {
  @ViewChild('video') videoplayer: any;
  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild(FooterComponent) footerComponent : FooterComponent;
  result: any;
  public modalEmailAddr;
  state = 'normal';
  private router;
  title = 'app';

constructor(private _router: Router, private footerToggle: FooterToggle, private subscriberService: SubscribeService,
  public jsonp: Jsonp, private sessionSt: SessionStorageService) {
  this.router = _router;
}

ngOnInit() {
  if (this.sessionSt.retrieve('visited') === null) {
    setTimeout(function() {document.getElementById('openModal').click(); }, 3000);
      this.sessionSt.store('visited', 'user');
  }
  return;
}

getSessionStorage() {
  alert(this.sessionSt.retrieve('visited'));
}

ngAfterContentInit() {
  if (this.videoplayer !== undefined) {
    this.videoplayer.nativeElement.muted = true;
    this.videoplayer.nativeElement.play();
  }
}

  @HostListener('document:click', ['$event'])
    public onClick(clickInfo) {
      if (this.footerToggle.displaySocial && clickInfo.detail !== 0) {
        if (window.innerHeight - clickInfo.clientY > document.getElementById('footer').offsetHeight) {
          if (this.footerComponent !== undefined) {
            this.footerComponent.checkFooter();
          }
        }
      }
    }

  isHidden(param) {
    if (this.router.url && this.router.url.indexOf(param) > -1) {
      return true;
    } else {
      return false;
    }
  }

  public subscribe(evnt) {
    // tslint:disable-next-line:max-line-length
    const url = '//boomboxcartel.us19.list-manage.com/subscribe/post-json?u=bf9e7e27f14f1fb62efd0cf88&amp;id=dfa6eb6cc2&subscribe=Subscribe&EMAIL=' + this.modalEmailAddr + '&c=JSONP_CALLBACK';
    this.jsonp.request(url, { method: 'Get' })
     .subscribe((res) => {
       this.result = res.json();
       if (this.result.result === 'success') {
         (<HTMLInputElement>document.getElementById('modalEmail')).style.display = 'none';
         document.getElementById('modalSubscribe-success').style.display = 'initial';
         setTimeout(function() {document.getElementById('closeModal').click(); }, 2000);
       } else {
         (<HTMLInputElement>document.getElementById('modalEmail')).value = '';
         (<HTMLInputElement>document.getElementById('modalEmail')).placeholder = 'Email already exists';
       }
     });
  }

  public keyBoardOpen() {
    if (window.innerWidth < 768) {
      document.getElementById('openModal').style.top = '0px';
    }
  }
}
