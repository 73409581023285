import { Component, OnInit, Injectable, HostListener, DoCheck, ViewChild, AfterContentInit } from '@angular/core';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
  })
  export class VideoComponent implements OnInit, DoCheck {

    constructor() { }

    ngOnInit() {
    }

    ngDoCheck(): void {
      setTimeout(_ => '');
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.computeHeight();
    }

    computeHeight() {
      const header = document.getElementById('header');
      const footer = document.getElementById('footer');
      const subscribeLink = document.getElementById('subscribe-link');
      return window.innerHeight - header.offsetHeight - footer.offsetHeight - subscribeLink.offsetHeight - 20 + 'px';
    }
  }
