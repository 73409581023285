import { Component, OnInit, Injectable, HostListener, DoCheck, ViewChild, AfterContentInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { AppConstants } from '../app.constants';
// import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import { FooterToggle } from '../footertoggle';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourComponent implements OnInit, DoCheck, AfterContentInit {
  @ViewChild('video') videoplayer: any;
  public oldContainerHeight;
  public tourDates;
  public currentMargin;

  constructor(private http: Http, private footerToggle: FooterToggle, private appConstants: AppConstants, private cdref: ChangeDetectorRef) {}

  ngOnInit() {
    this.getTourInfo().then((data) => {
      this.tourDates = data;
    }).catch((error) => {
      console.log(error);
    });
  }

  ngAfterContentInit() {
    if (this.videoplayer !== undefined) {
      this.videoplayer.nativeElement.muted = true;
      this.videoplayer.nativeElement.play();
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();    
  }

  ngDoCheck(): void {
    setTimeout(_ => '');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.computeHeight();
  }

  public getTourInfo(): Promise<object[]> {
    return this.http.get(this.appConstants.bandsInTownURL).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  public formatDate(dateStr: string): string {
    var date = new Date(dateStr);
    return this.appConstants.abbrvMonth[date.getMonth()] + ' ' + date.getDate();
  }

  public getState(venue) {
    if (venue.country !== 'United States') {
      if (venue.country.indexOf(',') > -1) {
         return venue.country.substring(0, venue.country.indexOf(','));
      }
      return venue.country;
    }
    return venue.region;
  }

  computeHeight() {
    const header = document.getElementById('header');
    const footer = document.getElementById('footer');
    const trackTour = document.getElementById('track-tour');
    return window.innerHeight - header.offsetHeight - footer.offsetHeight - trackTour.offsetHeight + 'px';
  }

  computeWidth() {
    if (window.innerWidth > 991) {
      const textContainerWidth = document.getElementsByClassName('tickets')[0] as HTMLElement;
      const textWidth = document.getElementsByClassName('ticket-link')[0] as HTMLElement;
        var width = (textContainerWidth.offsetWidth - 15 - textWidth.offsetWidth) / 2;
        if (window.innerWidth > 1199) {
          if (this.currentMargin === undefined || Math.abs(this.currentMargin - (width + 15)) > 5) {
            this.currentMargin = width + 15;
          }
        }
        if (this.currentMargin === undefined || Math.abs(this.currentMargin - width) > 5) {
          this.currentMargin = width
        }
        return this.currentMargin + 'px';
    } 
  }

  private extractData(res: Response) {
    let body = res.json();
    return body;
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }
}
