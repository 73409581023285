import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  public imageOverlays : any[] = [];
  constructor() { }

  ngOnInit() {
  }

  public displayOverlay(index) {
  	this.imageOverlays[index] = !this.imageOverlays[index];
  }

  public getStyle(index) {
    if (window.screen.width > 1024) {
    	return false;
    }
  	if (this.imageOverlays[index] == true) {
  		return false;
  	} else {
  		return true;
  	}
  }

}
