import {Injectable} from '@angular/core';
import {Http, Response, Headers, RequestOptions} from '@angular/http';


@Injectable()
export class SubscribeService {
  public headers;
  public responseObj;

  constructor(private http: Http) {
    this.headers = new Headers();
    this.responseObj = [];
  }

  public addSubscriber(email): Promise<object[]> {
    this.headers.append('Content-Type', 'application/json');
    return this.http.post('/api/addSubscriber',
      {'email': email}, {
        headers: this.headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
